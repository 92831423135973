import { formatISO } from 'date-fns';
import { useMemo } from 'react';
import { gql, useQuery } from 'urql';
import { useQueryParams } from 'use-query-params';

import { RangeDate } from '@/globalTypes';
import { mapVariables } from '@/utils';
import { makeOrderBy, makePagination } from '@/utils/pagination';
import {
  RiskAssessments,
  RiskAssessmentsVariables,
} from './__generated__/RiskAssessments';
import { queryParams } from './queryParams';

export const MAX_FINAL_SCORE = 100;

const QUERY = gql`
  query RiskAssessments(
    $playerGlobalId: String
    $percentage: RangeInt
    $finalScore: RangeInt
    $score: RangeInt
    $riskLevels: [RiskLevel!]
    $riskActions: [RiskAction!]
    $reviewStatus: [ReviewStatus!]
    $calculatedAt: RangeDate
    $lastReviewed: RangeDate
    $orderBy: RiskAggregationOrderBy
    $desc: Boolean
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    viewer {
      id
      riskAssessments(
        playerGlobalId: $playerGlobalId
        percentage: $percentage
        finalScore: $finalScore
        score: $score
        riskLevels: $riskLevels
        riskActions: $riskActions
        reviewStatus: $reviewStatus
        calculatedAt: $calculatedAt
        lastReviewedAt: $lastReviewed
        orderBy: $orderBy
        desc: $desc
        before: $before
        after: $after
        first: $first
        last: $last
      ) {
        pageInfo {
          endCursor
          hasNextPage
          startCursor
          hasPreviousPage
        }
        edges {
          node {
            playerGlobalId
            kycCheckStatus {
              status
            }
            players {
              id
              rawPlayerId
              brand {
                name
              }
            }
            finalScore
            riskLevel
            reviewStatusInfo {
              status
              lastReviewed
            }
            calculatedAt
            actions
          }
        }
      }
    }
  }
`;

export default function useRiskAssessment() {
  const [query, setQuery] = useQueryParams(queryParams);

  const finalScore = useMemo(() => {
    return query.finalScoreFrom || query.finalScoreTo
      ? {
          from: query.finalScoreFrom ? query.finalScoreFrom : 0,
          to: query.finalScoreTo ? query.finalScoreTo : MAX_FINAL_SCORE,
        }
      : undefined;
  }, [query.finalScoreFrom, query.finalScoreTo]);

  const calculatedAt = useMemo((): RangeDate | undefined => {
    if (query.calculatedAtFrom && query.calculatedAtTo) {
      return {
        from: formatISO(query.calculatedAtFrom),
        to: formatISO(query.calculatedAtTo),
      };
    }

    return undefined;
  }, [query.calculatedAtFrom, query.calculatedAtTo]);

  const lastReviewed = useMemo((): RangeDate | undefined => {
    if (query.lastReviewedFrom && query.lastReviewedTo) {
      return {
        from: formatISO(query.lastReviewedFrom),
        to: formatISO(query.lastReviewedTo),
      };
    }
    return undefined;
  }, [query.lastReviewedFrom, query.lastReviewedTo]);

  const variables = {
    ...mapVariables(query),
    finalScore,
    calculatedAt,
    lastReviewed,
  };

  const [{ data, fetching }, refresh] = useQuery<
    RiskAssessments,
    RiskAssessmentsVariables
  >({
    query: QUERY,
    variables,
  });

  return {
    refresh,
    fetching,
    defaultFilter: query,
    updateFilter: (values: {}) => setQuery(values, 'replaceIn'),
    ...makeOrderBy(query, setQuery),
    ...makePagination(query, setQuery, data?.viewer.riskAssessments.pageInfo),
    entries: data?.viewer.riskAssessments?.edges?.map((edge) => edge?.node),
  };
}
